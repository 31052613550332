<div class="container" *ngIf="!releaseNameSet">
  <form [formGroup]="releaseForm" (ngSubmit)="setReleaseName()">
    <label for="release-name">Enter Release Name:</label>
    <input
      id="release-name"
      formControlName="releaseName"
      placeholder="Release Name"
    />
    <input
      id="release-artist"
      formControlName="releaseArtist"
      placeholder="Release Artist"
    />
    <button type="submit" [disabled]="releaseForm.invalid">Submit</button>
  </form>
</div>
<div *ngIf="releaseNameSet">
  <div class="button-section">
    <button mat-button (click)="uploadAlbum()">Save</button>
    <button mat-button (click)="cancelUpload()">Cancel</button>
  </div>
  <div class="container">
    <!-- Left Section -->
    <div class="left">
      <h1>{{ editMode ? "Edit your release" : "Upload your release" }}</h1>
      <form [formGroup]="albumForm" (ngSubmit)="uploadAlbum()">
        <div class="section">
          <label>Album Art:</label> <br />
          <input
            style="width: 40%; margin-right: 2rem"
            type="file"
            (change)="onArtSelected($event)"
            accept="image/*"
          />
          <img
            [src]="imagePreview"
            *ngIf="imagePreview"
            style="max-width: 50px"
          />
        </div>

        <div class="top section">
          <label>Album Name:</label>
          <input formControlName="name" placeholder="Album Name" /> <br />
          <label>Album Artist:</label>
          <input formControlName="artist" placeholder="Album Artist" /> <br />
          <label>Spotify Album URI:</label>
          <input formControlName="uri" placeholder="Optional" /> <br />
          <label>Apple Pre-save Link:</label>
          <input formControlName="appleSave" placeholder="Optional" />
          <br />
          <select formControlName="type">
            <option value="" disabled selected>Release Type</option>
            <option value="EP">EP</option>
            <option value="Single">Single</option>
            <option value="unreleased EP">Unreleased EP</option>
            <option value="unreleased Single">Unreleased Single</option>
            <option value="topPick">Top Pick</option>
            <option value="liveShow">Live Show</option>
          </select>
          <input
            formControlName="released"
            type="date"
            placeholder="Release Date"
          />
          <br />
          <mat-slide-toggle formControlName="showing">
            Slide me to put in draft mode
          </mat-slide-toggle>
        </div>

        <!-- Add Track Button -->
        <div class="section">
          <button type="button" (click)="toggleTrackForm()">Add Track</button>
        </div>
      </form>
    </div>

    <!-- Right Section -->
    <div class="right">
      <!-- Track Form -->
      <form
        *ngIf="showTrackForm"
        [formGroup]="trackForm"
        (ngSubmit)="saveTrack()"
      >
        <h1>Track</h1>
        <div class="forms">
          <div>
            <label>Track Title:</label>
            <input formControlName="title" placeholder="Track Title" />
          </div>
          <div>
            <label>Track Artists:</label>
            <input formControlName="artists" placeholder="Track Artists" />
          </div>
          <div>
            <label>Spotify Track URI:</label>
            <input formControlName="uri" placeholder="Spotify URI" />
          </div>
          <div>
            <label>Apple Music Link:</label>
            <input formControlName="apple" placeholder="Apple Music Link" />
          </div>
          <div>
            <label>Written By:</label>
            <input formControlName="writers" placeholder="Anne Stead" />
          </div>
          <div>
            <label>Produced By:</label>
            <input formControlName="producers" placeholder="Tk808, Juicy J" />
          </div>
          <div>
            <label>Engineered By:</label>
            <input formControlName="engineers" placeholder="KY Engineering" />
          </div>

          <div>
            <textarea
              formControlName="lyrics"
              placeholder="Track Lyrics"
            ></textarea>
          </div>
          <div>
            <label>Upload Track:</label>
            <input
              type="file"
              (change)="onTrackSelected($event)"
              accept="audio/m4a"
            />
            <p>(.m4a files ONLY)</p>
          </div>
        </div>
        <mat-progress-bar
          *ngIf="progress > 0"
          mode="determinate"
          [value]="progress"
        ></mat-progress-bar>
        <button type="submit">Save Track</button>
      </form>
    </div>
  </div>
  <div class="track-list">
    <h2>Track List</h2>
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="trax" *ngFor="let track of songs; let i = index" cdkDrag>
        {{ i + 1 }}. {{ track.title }} - {{ track.artists }}
        <button (click)="editTrack(i, track)">Edit</button>

        <button (click)="removeTrack(i)">Remove</button>
      </div>
    </div>
  </div>
</div>
