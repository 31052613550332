<div class="main">
   <div class="back-button">
    <button (click)="goBack()">Back</button>
  </div>
  <div class="inner">
    <div class="top">
      <img [src]="playData?.artWork" alt="Cover art for an album" class="art">
      <div class="statsList">
        <div>
          <p class="song">Song</p>
          <h1 class="songTitle">{{playData.name}}</h1>
        </div>
        <div style="display: flex;">
          <div style="margin-inline-end: 64px;">
            <div aria-label="All-time streams " class="streams">
              <p aria-hidden="true">All-time streams</p>
              <div class="length">
                <p aria-hidden="true" class="plays" >{{playData?.plays?.length}}</p>
              </div>
            </div>
          </div>
          <div aria-label="Release date Sep 5, 2022">
            <p aria-hidden="true" class="Type__TypeElement-sc-goli3j-0 iKxupE">Release date</p>
            <p aria-hidden="true" class="plays">Sep 5, 2022</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>

      <section class="statList">
  <div class="titling-row">
    <span class="title-empty-space">Streams by Country</span>
    <span class="title-play-count">Plays</span>
    <span class="title-unique-listeners">Listeners</span>
  </div>

<hr>
    <ul class="track-list">
      <!-- Iterate over tracks within an album -->
      <li *ngFor="let song of this.countryData | keyvalue"   class="track-item">
        
        <!-- Display track name -->
        <span class="track-name">{{ song.key }}</span>
        <!-- Display count of plays for the track -->
        <span class="play-count">{{ song.value.playCount }}</span>
        <!-- Display count of unique listeners for the track -->
        <span class="unique-listeners">{{ song.value.uniqueUserCount }}</span>
      </li>
    </ul>
</section>
  <section class="statList">
  <div class="titling-row">
    <span class="title-empty-space">Streams by City</span>
    <span class="title-play-count">Plays</span>
    <span class="title-unique-listeners">Listeners</span>
  </div>

<hr>
    <ul class="track-list">
      <!-- Iterate over tracks within an album -->
      <li *ngFor="let song of this.cityData | keyvalue"   class="track-item">
      
        <!-- Display track name -->
        <span class="track-name">{{ song.key }}</span>
        <!-- Display count of plays for the track -->
        <span class="play-count">{{ song.value.playCount }}</span>
        <!-- Display count of unique listeners for the track -->
        <span class="unique-listeners">{{ song.value.uniqueUserCount }}</span>
      </li>
    </ul>
</section>
</div>



</div>


