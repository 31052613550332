import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { PlaysService } from "src/app/shared/services/plays/plays.service";

interface Track {
  title: string;
  playCount?: number;
  uniqueListenerCount?: number;
  editing?: boolean;
  url: string;
}

interface Album {
  art: string;
  artist: string;
  id: string;
  name: string;
  released: string;
  showing: boolean;
  tracks: Track[];
  type: string;
  editing?: boolean;
}

interface ResultTrack {
  playCount: number;
  uniqueListeners: Set<string>; // Assuming it's a set of user IDs
}

interface ResultAlbum {
  art: string;
  totalPlays: number;
  tracks: { [trackName: string]: ResultTrack };
}

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  albums: any[] = [];
  albumForm: FormGroup;
  editingAlbumId: string | null = null;
  discogData: any;
  upload: number;
  uploadProgress$: Observable<{ progress: number; song: string }> | undefined;
  mode: string = "";
  songUrl: string;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private playDataService: PlaysService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.albumForm = this.fb.group({
      name: "",
      artist: "",
      type: "",
      art: "",
      released: "",
      tracks: this.fb.array([]),
    });
  }

  toggleMode(event: any, album) {
    album.mode = event.checked ? "Active" : "Draft";
    this.playDataService.updateAlbum(this.toCamelCase(album.name), {
      showing: event.checked,
    });
  }

  deleteAlbum(album) {
    this.playDataService.deleteAlbum(this.toCamelCase(album)).then(() => {
      window.location.reload();
    });
  }
  ngOnInit(): void {
    this.getStats();
  }

  getStats() {
    this.fetchAlbums();
    this.getDisogData().then(() => {
      // Assuming this.albums and this.discogData are already populated
      this.albums = this.mergeAlbumAndDiscogData(this.albums, this.discogData);
      console.log(this.albums);
      this.albums.forEach((album) => {
        album.editing = false;
        album.mode = album.showing ? "Active" : "Draft";
      });
    });
  }

  async onFileSelected(event: Event, index: number) {
    console.log(this.albumForm.value);
    const file = (event.target as HTMLInputElement).files[0];
    const ref = `${this.albumForm.value.artist}/${this.albumForm.value.name}/test`;

    if (file) {
      // Upload file and subscribe to progress updates
      this.playDataService.uploadStorage(ref, file).subscribe({
        next: ({ progress, link }) => {
          this.upload = progress;
          console.log("Upload progress:", progress);
          if (progress === 100) {
            this.songUrl = link;
            console.log("Upload completed! Song URL:", link);
            // Handle completion (e.g., store song URL)
          }
        },
        error: (error) => {
          console.error("Error uploading file:", error);
          // Handle error (e.g., show error message)
        },
      });
    }
  }

  async getDisogData() {
    const result = {};
    const data = await this.playDataService.getStats();

    // Group songs by album
    for (const item of data) {
      if (!result[item.album]) {
        result[item.album] = {
          tracks: {},
          totalPlays: 0, // Initialize totalPlays for the album
        };
      }
      if (!result[item.album].tracks[item.track]) {
        result[item.album].tracks[item.track] = {
          playCount: 0,
          uniqueListeners: new Set(),
          plays: [],
        };
      }
      result[item.album].tracks[item.track].playCount++;
      result[item.album].totalPlays++; // Increment total plays for the album
      result[item.album].tracks[item.track].uniqueListeners.add(item.user);
      result[item.album].tracks[item.track].plays.push({
        city: item.city,
        country: item.country,
        state: item.state,
        date: item.date,
        user: item.user,
      });
    }

    // Convert Sets to counts for unique listeners
    for (const album in result) {
      for (const track in result[album].tracks) {
        const trackData = result[album].tracks[track];
        trackData.uniqueListenerCount = trackData.uniqueListeners.size; // Convert Set size to uniqueListenerCount
      }
    }

    // Fetch and assign artwork for each album
    for (const album of Object.keys(result)) {
      const artUrl = await this.playDataService.getArt(this.toCamelCase(album));
      result[album].art = artUrl;
    }

    this.discogData = result; // Ensure this.result is declared in your component
    return result;
  }

  toggleShowing(album: any): void {
    album.showing = !album.showing;
    const alby = this.toCamelCase(album.name);
    // Optionally, update the album state in your backend or service
  }

  fetchAlbums() {
    this.playDataService.getAllDiscography().then((albums) => {
      this.albums = albums;
    });
  }

  startEdit(album: any) {
    album.editing = true;
    this.editingAlbumId = album.id;
    this.albumForm.reset(); // Reset the form to clear previous edits
    this.albumForm.patchValue(album); // Fill the form with the album to edit
    // Handle tracks separately if needed
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }

  mergeAlbumAndDiscogData(
    albums: Album[],
    discogData: { [albumName: string]: ResultAlbum }
  ): Album[] {
    return albums.map((album) => {
      const resultAlbum = discogData[album.name]; // Use discogData instead of result
      if (resultAlbum) {
        album.tracks = album.tracks.map((track) => {
          const resultTrack = resultAlbum.tracks[track.title];
          if (resultTrack) {
            // Merge play count and unique listener count into the track
            track.playCount = resultTrack.playCount;
            track.uniqueListenerCount = resultTrack.uniqueListeners.size;
          } else {
            // Initialize to 0 if no data is found
            track.playCount = 0;
            track.uniqueListenerCount = 0;
          }
          return track;
        });
      }
      return album;
    });
  }

  edit(albumId: string) {
    this.router.navigate(["/upload", albumId]);
  }

  cancelEdit(album: Album) {
    album.editing = false;
    console.log(album.editing);
    // album.tracks.forEach((track) => (track.editing = false));

    console.log("After cancelEdit:", album); // Ensure this logs correctly
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  saveEdit(album: Album) {
    album.editing = false;
    this.playDataService.updateAlbum(this.editingAlbumId, album);
    this.editingAlbumId = "";
    // Save the changes to the backend or perform necessary actions
  }

  editAudio(album: Album, track: Track): void {
    track.editing = true;
  }

  editTrack(album: Album, track: Track): void {
    track.editing = true;
    console.log(this.albumForm.value);
    // Save the changes to the backend or perform necessary actions
  }

  cancelEditTrack(track: Track): void {
    track.editing = false;
  }

  async saveEditTrack(album: Album, index: number, track: Track) {
    track.editing = false;
    if (this.songUrl) {
      const ref1 = `${this.albumForm.value.artist}/${this.albumForm.value.name}/test`;
      const ref2 = `${this.albumForm.value.artist}/${this.albumForm.value.name}/${album.tracks[index].title}`;
      const newURL = await this.playDataService.copyFileAndGetURL(ref1, ref2);
      album.tracks[index].url = newURL;
      this.songUrl = "";
    }
    this.playDataService.updateAlbum(this.editingAlbumId, album);
    // this.saveAlbum();
    album.editing = false;
    // Save the changes to the backend or perform necessary actions
  }

  openSong(artwork, trackName, albumName) {
    // Access detailed play data for the song
    if (this.discogData[albumName]) {
      console.log("play");
      const plays = this.discogData[albumName].tracks[trackName].plays;
      const songIdentifier = this.toCamelCase(`${trackName}`);

      // Use the service to set the play data
      const data = {
        name: trackName,
        album: albumName,
        plays: plays,
        artWork: artwork,
      };
      this.playDataService.setPlayData(data);

      // Navigate to the songs component with the identifier
      this.router.navigate(["/songs", songIdentifier]);
      // Implement functionality to display these details as needed
    }
  }
  saveAlbum() {
    if (this.editingAlbumId) {
      this.playDataService
        .updateAlbum(this.editingAlbumId, this.albumForm.value)
        .then(() => {
          this.fetchAlbums(); // Refresh the list after update
          this.editingAlbumId = null; // Reset editing state
          this.albumForm.reset();
        });
    }
  }
}
