<section class="statList">
  <app-stats> </app-stats>
  <!-- <div class="titling-row">
    <span class="title-empty-space">Releases</span>
    <span class="title-play-count">Plays</span>
    <span class="title-unique-listeners">Listeners</span>
  </div>

  <div *ngFor="let album of result | keyvalue" class="album-container">
    <div class="album-header">
      <img class="album-art" [src]="album.value.art" [alt]="album.key">
      <h2 class="album-title">{{ album.key }}</h2>
    </div>

    <ul class="track-list">
      <li *ngFor="let track of album.value.tracks | keyvalue" class="track-item">
        <span class="track-name">{{ track.key }}</span>
        <span class="track-count">{{ track.value }}</span>
      </li>
    </ul>
  </div> -->
</section>

<!-- <div *ngIf="!admin" class="homepage-component">
  <h2 style="text-align: center">Unlock Premium Mode with a Subscription</h2>

  <div class="snip1214">
    <div class="plan">
      <h3 class="plan-title">Hit Squad</h3>
      <div class="plan-cost"><span class="plan-price">$1.49</span><span class="plan-type">/ Monthly</span></div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>Unlock Exclusive Content</li>
        <li><i class="ion-checkmark"> </i>Free Meet & Greets (Monthly)</li>
        <li><i class="ion-checkmark"> </i>Love from Toeknee Tee</li>
        <li><i class="ion-close-round"> </i>2x Tickets to my Concert (Monthly)</li>
        <li><i class="ion-close-round"> </i>1 Piece of Merch Quarterly</li>
        <li><i class="ion-close-round"> </i>Private A&R Talks about my upcoming songs</li>
      </ul>
      <div class="plan-select" (click)="subscribe(price1)"><a>Select Plan</a></div>
    </div>
    <div class="plan featured">
      <h3 class="plan-title">Drank'n Potnas</h3>
      <div class="plan-cost"><span class="plan-price">$4.99</span><span class="plan-type">/ Monthly</span></div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>Unlock Exclusive Content</li>
        <li><i class="ion-checkmark"> </i>Free Meet & Greets (Monthly)</li>
        <li><i class="ion-checkmark"> </i>Love from Toeknee Tee</li>
        <li><i class="ion-checkmark"> </i>2x Tickets to my Concert (Monthly)</li>
        <li><i class="ion-close-round"> </i>1 Piece of Merch Quarterly</li>
        <li><i class="ion-close-round"> </i>Private A&R Talks about my upcoming songs</li>
      </ul>
      <div class="plan-select" (click)="subscribe(price2)">
        <a>Select Plan</a>
      </div>
    </div>
    <div class="plan">
      <h3 class="plan-title">Burn Buddies</h3>
      <div class="plan-cost"><span class="plan-price">$19.99</span><span class="plan-type">/ Monthly</span></div>
      <ul class="plan-features">
        <li><i class="ion-checkmark"> </i>Unlock Exclusive Content</li>
        <li><i class="ion-checkmark"> </i>Free Meet & Greets (Monthly)</li>
        <li><i class="ion-checkmark"> </i>Love from Toeknee Tee</li>
        <li><i class="ion-checkmark"> </i>2x Tickets to my Concert (Monthly)</li>
        <li><i class="ion-checkmark"> </i>1 Piece of Merch Quarterly</li>
        <li><i class="ion-checkmark"> </i>Private A&R Talk about my upcoming songs</li>
      </ul>
      <div class="plan-select" (click)="subscribe(price3)"><a>Select Plan</a></div>
    </div>
  </div>

  <div class="centered">
    <div *ngIf="!doesNotHaveSubs$" class="manage-account" (click)="goPortal()">Manage Account</div>
  </div>
</div> -->
