import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import {
  DocumentData,
  DocumentReference,
  addDoc,
  collection,
  deleteDoc,
  doc,
  where,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  signInWithPopup,
  OAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getAuth,
  sendEmailVerification
} from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth: any;
  private userSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  ui$: Observable<any | null> = this.userSubject.asObservable();
  public user: any;
  streams: any;
  db: any;
  // auth: any;
  // public user$: Observable<any> = this.auth.user.pipe(shareReplay(1));

  constructor(private router: Router, private ngZone: NgZone) {
    //  this.user$.subscribe();
    // const use = this.user$.pipe(
    //   map(user => user ? user.uid : null) // Extract the uid property
    // ).subscribe(uid => {
    //   console.log(uid);
    //   this.localFire(uid);
    //   // You can now use this.uid in your template or component logic.
    // });
    // onAuthStateChanged(this.auth, (user) => {
    //   if (user) {
    //     this.user = user
    //     // ...
    //   } else {
    //     // User is signed out
    //     // ...
    //   }
    // });
  }

  setFire(db, auth) {
    this.db = db;
    this.auth = auth;
  }

  setUser(user) {
    this.user = user;
    console.log(user);
    this.ui$ = user;
    this.userSubject.next(user);
  }

  setLogged() {
    localStorage.setItem('isLoggedIn', 'true');
  }

  async addOrg(form) {}

  async getUserByEmail(email) {
    console.log(email);
    try {
      const userRef = collection(getFirestore(), 'users');

      // Create a query against the collection.
      const q = query(userRef, where('email', '==', email));

      // Query the users collection for documents with the specified email
      const querySnapshot = await getDocs(q);
      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log('No matching documents.');
        return null;
      }

      // Process the query result
      let userDoc;
      localStorage.setItem('user', JSON.stringify(querySnapshot.docs[0].data()));
      userDoc = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
      await this.reinitializeFirebase(userDoc.firebase);
      return userDoc;
    } catch (error) {
      console.error('Error getting documents: ', error);
    }
  }

  // async localFire(email) {
  //   //get uid and bring in account details from firebase
  //   try {
  //     const ref = doc(this.db, 'users', uid);
  //     const userDoc = await getDoc(ref);
  //     if (userDoc.exists) {
  //       // Do something with the user document data here
  //       const userData = userDoc.data();
  //       console.log(userData);
  //       this.user = userData;
  //       this.userSubject.next(this.user);
  //       localStorage.setItem('user', JSON.stringify(this.user));
  //       JSON.parse(localStorage.getItem('user')!);
  //     } else {
  //       // Handle the case where the document doesn't exist
  //       console.log('User document not found.');
  //     }
  //   } catch (error) {
  //     // Handle any errors that might occur during the Firestore operation
  //     console.error('Error fetching user data:', error);
  //   }
  // }

  reinitializeFirebase(newConfig) {
    if (firebase.apps.length) {
      firebase
        .app()
        .delete()
        .then(() => {
          initializeApp(newConfig, 'secondary');
          this.db = getFirestore();
          console.log('Firebase reinitialized with new config');
          // Perform additional setup if needed
        })
        .catch((error) => {
          console.error('Error deleting Firebase app:', error);
        });
    } else {
      initializeApp(newConfig, 'secondary');
      this.db = getFirestore();

      console.log('Firebase initialized with new config');
      // Perform additional setup if needed
    }
  }

  signInWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (res) => {
        this.setLogged();

        try {
          const user = await this.getUserByEmail(res.user.email);
          this.ui$ = user;
          console.log('user ', user);
          return this.ngZone.run(() => this.router.navigate(['/home']));
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        } catch (error) {
          // Handle error
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  signInWithEmail(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then((res) => {
        console.log('AuthService::Successful Email login', res);
        this.setLogged();

        this.getUserByEmail(res.user.email);
        return this.ngZone.run(() => this.router.navigate(['/home']));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  signUpWithEmail(email: string, password: string) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        this.setLogged();
        const auth = getAuth();

        // Assuming 'user' is the newly registered user
        const user = auth.currentUser;

        try {
          const verified = res.user.emailVerified ? true : false;

          const user = this.getUserByEmail(res.user.email);
          return this.ngZone.run(() => this.router.navigate(['/home']));
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        } catch (error) {
          // Handle error
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  getUser() {
    return this.user;
  }

  // isSmallBatchDevsLoggedIn$(): Observable<boolean> {
  //   return this.user$.pipe(map(user => user && user.email));
  // }

  logout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.userSubject.next('none');
        localStorage.clear();
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  // Not Yet Implemented
  signInWithFacebook() {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        this.setLogged();
        this.getUserByEmail(res.user.email);
        return this.ngZone.run(() => this.router.navigate(['/home']));
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  // Not Yet Implemented
  signInWithApple() {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
      .then(async (res) => {
        this.setLogged();
        try {
          this.getUserByEmail(res.user.email);
          return this.ngZone.run(() => this.router.navigate(['/home']));
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        } catch (error) {
          // Handle error
        }
      })
      .catch((error) => {
        // Handle error
      });
  }

  async createStripeCustomer(user: any) {
    console.log('stripe  create ' + user);
    let link = 'https://tt.apps4artists.uk/backend/create-stripe-customer';
    axios
      .post(link, { user })
      .then((data) => {
        console.log('Stripe customer created:', data.data.stripeId);
      })
      .catch((error) => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });
  }
}

function docData(
  albumDocRef: DocumentReference<DocumentData, DocumentData>,
  arg1: { idField: string }
): Observable<any> {
  throw new Error('Function not implemented.');
}
