import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AdminService } from "src/app/shared/services/admin.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AboutComponent implements OnInit {
  clientForm: FormGroup;
  imagePreview: string | ArrayBuffer;
  picBlob: File;

  constructor(
    private fb: FormBuilder,
    private admin: AdminService,
    private router: Router
  ) {
    this.clientForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      firebase: this.fb.group({
        apiKey: ["", Validators.required],
        appId: ["", Validators.required],
        authDomain: ["", Validators.required],
        databaseURL: ["", Validators.required],
        measurementId: ["", Validators.required],
        messagingSenderId: ["", Validators.required],
        projectId: ["", Validators.required],
        storageBucket: ["", Validators.required],
      }),
      name: ["", Validators.required],
      pic: [""],
    });
  }

  ngOnInit(): void {}

  async onArtSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imagePreview = reader.result; // This is the preview URL
      };

      reader.readAsDataURL(file);
      this.picBlob = file;
      // Use the file as a Blob for uploads or further processing
      // file is already a Blob
    }
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }

  async onSubmit(): Promise<void> {
    if (this.clientForm.valid) {
      console.log("Form Submitted!", this.clientForm.value);
      // Here you can send the form data to your backend service
      const ref = "organizations";
      await this.admin.uploadStorage(ref, this.picBlob).subscribe({
        next: async ({ progress, link }) => {
          console.log("Upload progress:", progress);
          if (progress === 100) {
            this.clientForm.value.pic = link;
            console.log("Upload completed! Song Artwork:", link);

            await this.admin.addUser(
              this.clientForm.value,
              this.toCamelCase(this.clientForm.value.name)
            );
            this.router.navigate(["home"]);

            // Handle completion (e.g., store song URL)
          }
        },
        error: (error) => {
          console.error("Error uploading file:", error);
          // Handle error (e.g., show error message)
        },
      });
    } else {
      console.log("Form is invalid");
    }
  }

  preSaveAlbum() {
    this.admin.preSaveAlbum();
  }

  preSaveSong() {
    this.admin.preSaveSong();
  }
}
