import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { PlaysService } from "src/app/shared/services/plays/plays.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
})
export class UploadComponent implements OnInit {
  imagePreview: string | ArrayBuffer = "";
  albumForm: UntypedFormGroup;
  releaseForm: FormGroup;
  tracksFiles: Blob;
  trackIndex: number = 0;
  showing: boolean = true;
  picBlob: any;
  trackForm: FormGroup;
  songs: any[] = [];
  showTrackForm = false;
  releaseNameSet = false;
  progress: number = 0;
  albumName: string;
  albumArtist: any;
  editMode: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private play: PlaysService,
    private route: ActivatedRoute
  ) {
    this.albumForm = this.fb.group({
      name: ["", Validators.required],
      artist: ["", Validators.required],
      type: ["", Validators.required],
      art: ["", Validators.required],
      released: ["", Validators.required],
      uri: new UntypedFormControl(""),
      appleSave: new UntypedFormControl(""),
      tracks: this.fb.array([]),
      showing: [this.showing, Validators.required],
    });

    this.releaseForm = this.fb.group({
      releaseName: ["", Validators.required],
      releaseArtist: ["", Validators.required],
    });

    this.trackForm = this.fb.group({
      title: [""],
      artists: [""],
      url: [""],
      uri: [""],
      apple: [""],
      lyrics: [""],
      producers: [""],
      engineers: [""],
      writers: [""],
      comments: [],
      likes: [],
    });
  }

  setReleaseName() {
    this.albumName = this.releaseForm.value.releaseName;
    this.albumArtist = this.releaseForm.value.releaseArtist;

    this.albumForm.get("name").setValue(this.albumName);
    this.albumForm.get("artist").setValue(this.albumArtist); // Set album name
    console.log(this.albumForm.value.artist);
    console.log(this.albumForm.value.name);
    this.releaseNameSet = true; // Show the album form after submission
  }

  toggleTrackForm() {
    this.showTrackForm = !this.showTrackForm;
  }

  saveTrack() {
    const ref = `${this.albumForm.value.artist}/${this.albumForm.value.name}/${this.trackForm.value.title}`;
    console.log(ref);
    this.play.uploadStorage(ref, this.tracksFiles).subscribe({
      next: ({ progress, link }) => {
        this.progress = progress;

        if (progress === 100 && link) {
          if (this.editMode) {
            this.play.deleteFile(this.trackForm.value.url);
            this.trackForm.value.url = link;

            this.songs[this.trackIndex] = this.trackForm.value;
          } else {
            this.trackForm.value.url = link;
            this.songs.push(this.trackForm.value);
          }
          this.trackForm.reset();
          this.showTrackForm = false;
          console.log("Upload completed! Song URL:", link);

          // Handle completion (e.g., store song URL)
        }
      },
      error: (error) => {
        console.error("Error uploading file:", error);
        // Handle error (e.g., show error message)
      },
    });

    // this.tracks.push(this.trackForm.value);
  }

  removeTrack(index: number) {
    this.songs.splice(index, 1);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.songs, event.previousIndex, event.currentIndex);
  }

  get tracks(): UntypedFormArray {
    return this.albumForm.get("tracks") as UntypedFormArray;
  }

  addTrack() {
    const track = this.fb.group({
      title: "",
      url: "",
      lyrics: "",
      artists: "",
      comments: [],
      likes: [],
      apple: "",
      uri: "",
    });
    this.tracks.push(track);
  }

  async onArtSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imagePreview = reader.result; // This is the preview URL
      };

      reader.readAsDataURL(file);
      console.log(file);
      this.picBlob = file;

      // Use the file as a Blob for uploads or further processing
      // file is already a Blob
    }
  }

  onTrackSelected(event: Event, index: number) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.tracksFiles = file; // Store the Blob in an array
      // You can create a FileReader instance here if you need to read the file,
      // for example, to get the duration or other metadata
    }
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }

  uploadArt() {}

  async uploadAlbum() {
    const alb = this.toCamelCase(this.albumName);
    const ref = `${this.albumArtist}/${this.albumName}/artwork`;

    if (this.editMode) {
      this.albumForm.value.tracks = this.songs;
      if (this.picBlob) {
        this.play.uploadStorage(ref, this.picBlob).subscribe({
          next: async ({ progress, link }) => {
            console.log("Upload progress:", progress);
            if (progress === 100 && link) {
              console.log(this.albumForm.value);
              this.albumForm.value.art = link;
              this.play.updateAlbum(alb, this.albumForm.value);

              // Handle completion (e.g., store song URL)
            }
          },
          error: (error) => {
            console.error("Error uploading file:", error);
            // Handle error (e.g., show error message)
          },
        });
      } else {
        this.play.updateAlbum(alb, this.albumForm.value);
      }

      setTimeout(() => {
        this.router.navigate(["home"]);
      }, 1000);
    } else {
      await this.play.uploadStorage(ref, this.picBlob).subscribe({
        next: async ({ progress, link }) => {
          console.log("Upload progress:", progress);
          if (progress === 100) {
            console.log(this.albumForm.value);
            this.albumForm.value.art = link;
            this.albumForm.value.tracks = this.songs;
            console.log("Upload completed! Song Artwork:", link);
            await this.play.addDisco(this.albumForm.value, alb);
            this.router.navigate(["home"]);

            // Handle completion (e.g., store song URL)
          }
        },
        error: (error) => {
          console.error("Error uploading file:", error);
          // Handle error (e.g., show error message)
        },
      });
    }
  }

  editTrack(i, track) {
    this.toggleTrackForm();
    this.trackIndex = i;
    console.log(track);
    this.trackForm.patchValue(track);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const albumId = params["albumId"];

      let foundAlbum;
      if (albumId != "new") {
        this.albumName = albumId;
        this.editMode = true;
        this.releaseNameSet = true;

        if (this.play.discography) {
          foundAlbum = this.play.discography.find(
            (album) => album.id === albumId
          );
          this.songs = foundAlbum.tracks;
          this.imagePreview = foundAlbum.art;
          this.albumForm.patchValue(foundAlbum);
        } else {
          this.play.getAlbumById(albumId).subscribe((album) => {
            console.log(album);
            foundAlbum = album;
            this.songs = foundAlbum.tracks;
            this.imagePreview = foundAlbum.art;

            this.albumForm.patchValue(foundAlbum);
          });
        }

        // Pre-fill the form with album data
      } else {
      }
    });
  }

  cancel() {
    this.router.navigate(["/home"]);
  }
}
