import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paid',
  templateUrl: './paid.component.html',
  styleUrls: ['./paid.component.scss']
})
export class PaidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
