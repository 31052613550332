<div class="header">
  <div *ngIf="user?.name" class="header-links-nav">
    <a
      routerLink="/"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Home</a
    >
    <a
      routerLink="/locations"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Locations</a
    >
    <a
      routerLink="/upload/new"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Upload
    </a>

    <a *ngIf="user.admin" routerLink="about" routerLinkActive="active-link"
      >Admin</a
    >
    <a (click)="logoutUser()">Logout</a>

    <div class="header-right">
      <a class="logged-in-user">{{ user?.name }}</a>
    </div>
  </div>
  <div class="header-image-div">
    <div class="a4a">
      <img style="width: 3rem" src="../../../../../assets/a4alogo.png" />
      <span>Apps 4 Artists</span>
    </div>

    <img *ngIf="user" class="header-image" [src]="user?.pic" />
  </div>
</div>
