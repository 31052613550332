<html lang="en">
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Toeknee Tee Subscription</title>

<body>
    <div class="success-container">
        <div class="success-icon">&#10004;</div>
        <div class="success-message">Congratulations! You've successfully signed up to support Toeknee Tee's the STRONG
            way.</div>
        <div class="instructions">To unlock your bonus features, please log out of the Toeknee Tee app and log back in.
        </div>
    </div>

</body>

</html>