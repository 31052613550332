import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomepageComponent } from "./modules/home/pages/homepage/homepage.component";
import { EditPostComponent } from "./modules/home/pages/edit-post/edit-post.component";
import { LoginComponent } from "./modules/home/pages/login/login.component";
import { AboutComponent } from "./modules/home/pages/admin/admin.component";
import { AuthGuard } from "./guards/auth.guard";
import { SuccessComponent } from "./modules/home/pages/success/success.component";
import { FailedComponent } from "./modules/home/pages/failed/failed.component";
import { UploadComponent } from "./modules/home/pages/upload/upload.component";
import { StatsComponent } from "./modules/home/pages/stats/stats.component";
import { SongComponent } from "./song/song.component";
import { LocationsComponent } from "./modules/home/pages/locations/locations.component";
import { PaidComponent } from "./modules/home/pages/paid/paid.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: HomepageComponent,
  },

  {
    path: "home",
    canActivate: [AuthGuard],
    pathMatch: "full",
    component: HomepageComponent,
  },
  {
    path: "success",
    component: SuccessComponent,
  },

  {
    path: "upload/:albumId",
    canActivate: [AuthGuard],
    component: UploadComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  {
    path: "cancel",
    component: FailedComponent,
  },
  {
    path: "paid",
    component: PaidComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },

  {
    path: "songs/:songID",
    canActivate: [AuthGuard],
    component: SongComponent,
  },
  {
    path: "stats",
    canActivate: [AuthGuard],
    component: StatsComponent,
  },

  {
    path: "locations",
    canActivate: [AuthGuard],
    component: LocationsComponent,
  },

  // Wildcard route to catch undefined routes
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
