import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './modules/home/pages/homepage/homepage.component';
import { EditPostComponent } from './modules/home/pages/edit-post/edit-post.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DonateComponent } from './shared/components/donate/donate.component';
import { AboutComponent } from './modules/home/pages/admin/admin.component';
import { SbdSpinnerComponent } from './shared/components/sbd-spinner/sbd-spinner.component';
import { NavComponent } from './modules/home/pages/homepage-nav/nav.component';
import { SuccessComponent } from './modules/home/pages/success/success.component';
import { FailedComponent } from './modules/home/pages/failed/failed.component';
import { UploadComponent } from './modules/home/pages/upload/upload.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SongComponent } from './song/song.component';
import { StatsComponent } from './modules/home/pages/stats/stats.component';
import { LocationsComponent } from './modules/home/pages/locations/locations.component';
import { PaidComponent } from './modules/home/pages/paid/paid.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PlaysMapComponent } from './plays-map/plays-map.component';
import { HttpClientModule } from '@angular/common/http';
import { MatButton, MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    NavComponent,
    EditPostComponent,
    LoginComponent,

    DonateComponent,
    AboutComponent,
    SbdSpinnerComponent,
    SuccessComponent,
    FailedComponent,
    StatsComponent,
    UploadComponent,
    SongComponent,
    LocationsComponent,
    PaidComponent,
    PlaysMapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
  bootstrap: [AppComponent]
})
export class AppModule {}
