<html lang="en">
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Toeknee Tee Subscription</title>

  <body>
    <div class="success-container">
      <div class="success-icon">&#10004;</div>
      <div class="success-message">Congratulations! You've successfully Paid.</div>
      <div class="instructions">Please check your email for further details or tickets.</div>
    </div>
  </body>
</html>
