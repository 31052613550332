import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check if the user is in local storage
    const jsonString = localStorage.getItem('user'); // Replace 'yourLocalStorageKey' with the key you used for storing the data.
    // Check if the JSON string is not null or undefined
    if (jsonString) {
      try {
        // Parse the JSON string into a JavaScript object
        const userObject = JSON.parse(jsonString);
        console.log(userObject);
        this.auth.setUser(userObject);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
      return true;
    } else {
      console.log('guard blocking');
      // User not found in local storage, navigate to login
      this.router.navigate(['/login']);
      return false;
    }
  }
}
