import { Component, OnInit } from '@angular/core';
import { PlaysMapComponent } from 'src/app/plays-map/plays-map.component';
import { AuthService } from 'src/app/shared/services/authentication/auth.service';
import { PlaysService } from 'src/app/shared/services/plays/plays.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  cityData: any = {};
  countryData: any = {};
  constructor(private authService: AuthService, private play: PlaysService) {}

  ngOnInit(): void {
    this.getDisog();
  }

  async getDisog() {
    const result = {};
    const data = await this.play.returnStreams();
    this.groupDataByCity(data);
    this.groupDataByCountry(data);
  }

  groupDataByCountry(data: any[]): void {
    data.forEach((item) => {
      const country = item.country;
      if (!this.countryData[country]) {
        this.countryData[country] = { playCount: 0, uniqueUsers: new Set() };
      }
      this.countryData[country].playCount++;
      this.countryData[country].uniqueUsers.add(item.user);
    });

    // Convert Sets to counts for unique users
    Object.keys(this.countryData).forEach((country) => {
      this.countryData[country].uniqueUserCount = this.countryData[country].uniqueUsers.size;
    });
    console.log(this.countryData);
  }

  groupDataByCity(data: any[]): void {
    data.forEach((item) => {
      const city = item.city;
      if (!this.cityData[city]) {
        this.cityData[city] = { playCount: 0, uniqueUsers: new Set() };
      }
      this.cityData[city].playCount++;
      this.cityData[city].uniqueUsers.add(item.user);
    });

    // Convert Sets to counts for unique users
    Object.keys(this.cityData).forEach((city) => {
      this.cityData[city].uniqueUserCount = this.cityData[city].uniqueUsers.size;
    });
  }
}
