<div class="main">
  <!-- <app-plays-map></app-plays-map> -->

  <div class="inner">
    <section class="statList" style="padding-bottom: 2rem">
      <h1>Streams By Country</h1>
      <div class="titling-row">
        <span class="title-empty-space"></span>
        <span class="title-play-count">Plays</span>
        <span class="title-unique-listeners">Listeners</span>
      </div>

      <hr />
      <ul class="track-list">
        <li *ngFor="let song of this.countryData | keyvalue" class="track-item">
          <span class="track-name">{{ song.key }}</span>
          <span class="play-count">{{ song.value.playCount }}</span>
          <span class="unique-listeners">{{ song.value.uniqueUserCount }}</span>
        </li>
      </ul>
    </section>
    <section class="statList">
      <h1>Streams by City</h1>
      <div class="titling-row">
        <span class="title-empty-space"></span>
        <span class="title-play-count">Plays</span>
        <span class="title-unique-listeners">Listeners</span>
      </div>

      <hr />
      <ul class="track-list">
        <li *ngFor="let song of this.cityData | keyvalue" class="track-item">
          <span class="track-name">{{ song.key }}</span>
          <span class="play-count">{{ song.value.playCount }}</span>
          <span class="unique-listeners">{{ song.value.uniqueUserCount }}</span>
        </li>
      </ul>
    </section>
  </div>
</div>
