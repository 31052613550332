import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../shared/services/authentication/auth.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  private uiSubscription: Subscription;
  user: any;
  currentUser$: Observable<string> = this.authService.ui$.pipe(map((user) => (user ? user.email : null)));

  constructor(private authService: AuthService, private router: Router) {
    this.uiSubscription = this.authService.ui$.subscribe((user) => {
      console.log(user);
      this.user = user;
    });
  }
  ngOnInit(): void {}

  logoutUser() {
    this.router.navigate(['/login']);
    this.authService.logout();
    this.user = '';
  }
}
