<form [formGroup]="clientForm" style="width: 45vw" (ngSubmit)="onSubmit()">
  <div>
    <label for="email">Email:</label>
    <input id="email" formControlName="email" />
    <div
      *ngIf="clientForm.get('email').invalid && clientForm.get('email').touched"
    >
      <small>Email is required and should be a valid email address.</small>
    </div>
  </div>

  <fieldset formGroupName="firebase">
    <legend>Firebase</legend>
    <div>
      <label for="apiKey">API Key:</label>
      <input id="apiKey" formControlName="apiKey" />
      <div
        *ngIf="
          clientForm.get('firebase.apiKey').invalid &&
          clientForm.get('firebase.apiKey').touched
        "
      >
        <small>API Key is required.</small>
      </div>
    </div>
    <div>
      <label for="appId">App ID:</label>
      <input id="appId" formControlName="appId" />
      <div
        *ngIf="
          clientForm.get('firebase.appId').invalid &&
          clientForm.get('firebase.appId').touched
        "
      >
        <small>App ID is required.</small>
      </div>
    </div>
    <div>
      <label for="authDomain">Auth Domain:</label>
      <input id="authDomain" formControlName="authDomain" />
      <div
        *ngIf="
          clientForm.get('firebase.authDomain').invalid &&
          clientForm.get('firebase.authDomain').touched
        "
      >
        <small>Auth Domain is required.</small>
      </div>
    </div>
    <div>
      <label for="databaseURL">Database URL:</label>
      <input id="databaseURL" formControlName="databaseURL" />
      <div
        *ngIf="
          clientForm.get('firebase.databaseURL').invalid &&
          clientForm.get('firebase.databaseURL').touched
        "
      >
        <small>Database URL is required.</small>
      </div>
    </div>
    <div>
      <label for="measurementId">Measurement ID:</label>
      <input id="measurementId" formControlName="measurementId" />
      <div
        *ngIf="
          clientForm.get('firebase.measurementId').invalid &&
          clientForm.get('firebase.measurementId').touched
        "
      >
        <small>Measurement ID is required.</small>
      </div>
    </div>
    <div>
      <label for="messagingSenderId">Messaging Sender ID:</label>
      <input id="messagingSenderId" formControlName="messagingSenderId" />
      <div
        *ngIf="
          clientForm.get('firebase.messagingSenderId').invalid &&
          clientForm.get('firebase.messagingSenderId').touched
        "
      >
        <small>Messaging Sender ID is required.</small>
      </div>
    </div>
    <div>
      <label for="projectId">Project ID:</label>
      <input id="projectId" formControlName="projectId" />
      <div
        *ngIf="
          clientForm.get('firebase.projectId').invalid &&
          clientForm.get('firebase.projectId').touched
        "
      >
        <small>Project ID is required.</small>
      </div>
    </div>
    <div>
      <label for="storageBucket">Storage Bucket:</label>
      <input id="storageBucket" formControlName="storageBucket" />
      <div
        *ngIf="
          clientForm.get('firebase.storageBucket').invalid &&
          clientForm.get('firebase.storageBucket').touched
        "
      >
        <small>Storage Bucket is required.</small>
      </div>
    </div>
  </fieldset>

  <div>
    <label for="name">Name:</label>
    <input id="name" formControlName="name" />
    <div
      *ngIf="clientForm.get('name').invalid && clientForm.get('name').touched"
    >
      <small>Name is required.</small>
    </div>
  </div>

  <div class="section">
    <label>Org Art:</label> <br />
    <input type="file" (change)="onArtSelected($event)" accept="image/*" />
    <img
      [src]="imagePreview"
      *ngIf="imagePreview"
      style="max-width: 200px; max-height: 200px"
    />
  </div>

  <button mat-raised-button type="submit" [disabled]="clientForm.invalid">
    Add Client
  </button>
</form>

<button
  style="background: #1db954; color: white"
  (click)="preSaveAlbum()"
  mat-raised-button
>
  Activate Spotify Album Pre-save
</button>

<button
  style="background: #1db954; color: white"
  (click)="preSaveSong()"
  mat-raised-button
>
  Activate Spotify Single Pre-save
</button>
