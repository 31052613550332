import { Injectable } from "@angular/core";
import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import {
  DocumentData,
  DocumentReference,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  StorageReference,
  UploadTask,
  UploadTaskSnapshot,
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { BehaviorSubject, from, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PlaysService {
  private playData = new BehaviorSubject<any>(null);
  db: any;
  app: FirebaseApp;
  streams: any;
  private storage;
  discography: any[];
  constructor() {
    const firebase = JSON.parse(localStorage.getItem("user")).firebase;

    this.app = initializeApp(firebase, "secondary");
    this.db = getFirestore(this.app);
    this.storage = getStorage(this.app);
    console.log(this.db);
    // console.log((this.db = JSON.parse(localStorage.getItem('user')).firebase));
  }

  setPlayData(data: any) {
    this.playData.next(data);
  }

  getPlayData(): Observable<any> {
    return this.playData.asObservable();
  }

  async addDisco(form, album) {
    await setDoc(doc(this.db, "discography", album), form);
  }

  async getAllDiscography() {
    const discographyRef = collection(this.db, "discography");
    // Create a query that orders documents by the 'released' field, descending
    const q = query(discographyRef, orderBy("released", "desc"));
    try {
      const querySnapshot = await getDocs(q);
      const discographyList = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        discographyList.push({ id: doc.id, ...doc.data() });
      });
      this.discography = discographyList;
      return discographyList; // This list is now sorted by release date, latest first
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error; // or handle it as needed
    }
  }

  getAlbumById(id: string): Observable<any> {
    const albumDocRef = doc(this.db, `discography/${id}`);

    return from(getDoc(albumDocRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          console.log("No such document!");
          return null; // Or handle the case where the document doesn't exist
        }
      }),
      catchError((error) => {
        console.error("Error fetching album data:", error);
        return throwError(() => error); // Re-throw the error for further handling
      })
    );
  }

  async deleteAlbum(id: string): Promise<void> {
    const albumDocRef = doc(this.db, `discography/${id}`);
    return deleteDoc(albumDocRef);
  }

  async deleteFile(filePath: string) {
    try {
      const storageRef = ref(this.storage, filePath);
      await deleteObject(storageRef);
      console.log("File deleted successfully!");
    } catch (error) {
      console.error("Error   deleting file:", error);
    }
  }

  async updateAlbum(id: string, data: any): Promise<void> {
    const albumDocRef = doc(this.db, `discography/${id}`);
    return updateDoc(albumDocRef, data);
  }

  async getArt(title) {
    // ... inside an async function or method
    try {
      const ref = doc(this.db, "discography", title);
      const docSnapshot = await getDoc(ref);

      if (docSnapshot.exists()) {
        const albumData = docSnapshot.data();
        const art = albumData.art; // Here is your 'art' field

        return art;
        // Do something with the art URL...
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  }

  uploadStorage(
    refPath: string,
    file: Blob
  ): Observable<{ progress: number; link: string }> {
    const storageRef = ref(this.storage, refPath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Create an observable to track upload progress and return download URL
    return new Observable<{ progress: number; link: string }>((observer) => {
      uploadTask.on(
        "state_changed",
        (snapshot: UploadTaskSnapshot) => {
          // Calculate progress percentage
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          observer.next({ progress, link: "" }); // Emit progress, URL will be updated later
        },
        (error) => {
          observer.error(error); // Handle error
        },
        () => {
          // Upload completed successfully, get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((link) => {
            observer.next({ progress: 100, link: link }); // Update progress with 100% and URL
            observer.complete(); // Complete the observable
          });
        }
      );
    });
  }

  async copyFileAndGetURL(ref1: string, ref2: string): Promise<string> {
    const tempRef = ref(this.storage, ref1);
    const finalRef = ref(this.storage, ref2);
    const tempURL = await getDownloadURL(tempRef);
    const response = await fetch(tempURL);
    const blob = await response.blob();

    await uploadBytes(finalRef, blob);
    const newURL = await getDownloadURL(finalRef);
    await deleteObject(tempRef);

    return newURL;
  }
  async getStats() {
    let data = [];
    const querySnapshot = await getDocs(collection(this.db, "streams"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    this.streams = data;
    return data;
  }

  returnStreams() {
    return this.streams;
  }
}
