import { Component, OnInit } from '@angular/core';
import { PlaysService } from '../shared/services/plays/plays.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.scss']
})
export class SongComponent implements OnInit {
  playData: any;
  cityData: any = {};
  countryData: any = {};
  constructor(private playDataService: PlaysService, private router: Router) {}

  ngOnInit() {
    this.playDataService
      .getPlayData()
      .pipe(take(1))
      .subscribe((data) => {
        this.playData = data;
        this.groupDataByCity(data.plays);
        this.groupDataByCountry(data.plays);
      });
  }

  groupDataByCountry(data: any[]): void {
    data.forEach((item) => {
      const country = item.country;
      if (!this.countryData[country]) {
        this.countryData[country] = { playCount: 0, uniqueUsers: new Set() };
      }
      this.countryData[country].playCount++;
      this.countryData[country].uniqueUsers.add(item.user);
    });

    // Convert Sets to counts for unique users
    Object.keys(this.countryData).forEach((country) => {
      this.countryData[country].uniqueUserCount = this.countryData[country].uniqueUsers.size;
    });
  }

  goBack() {
    this.router.navigateByUrl('/home');
  }

  groupDataByCity(data: any[]): void {
    data.forEach((item) => {
      const city = item.city;
      if (!this.cityData[city]) {
        this.cityData[city] = { playCount: 0, uniqueUsers: new Set() };
      }
      this.cityData[city].playCount++;
      this.cityData[city].uniqueUsers.add(item.user);
    });

    // Convert Sets to counts for unique users
    Object.keys(this.cityData).forEach((city) => {
      this.cityData[city].uniqueUserCount = this.cityData[city].uniqueUsers.size;
    });
    console.log(this.cityData);
  }
}
