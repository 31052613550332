import { Component, OnInit } from '@angular/core';
import { BlogPost } from '../../../../shared/models/blog-post';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../../shared/services/authentication/auth.service';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { Contact } from '../../../../../../shared/models/contact';
import 'firebase/functions';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { objectExists } from '../../../../shared/services/utilites/utilities.service';
import axios from 'axios';
import { PlaysService } from 'src/app/shared/services/plays/plays.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  //readonly isUserSmallBatchDevs$: Observable<boolean> = this.authService.isSmallBatchDevsLoggedIn$();
  stripe!: Stripe;
  result: any;

  readonly currentUser$ = this.authService.getUser();
  private uiSubscription: Subscription;
  user: any;
  price1: string = 'price_1Ki13xJd3fzjfrRusZYZkkNd';
  price2: string = 'price_1Ki15FJd3fzjfrRubDouQ7Im';
  price3: string = 'price_1Ki173Jd3fzjfrRuDItIZYoU';
  doesNotHaveSubs$: boolean = true;
  admin = false;
  constructor(private router: Router, private authService: AuthService, private playDataService: PlaysService) {
    this.getDisog();
  }

  async getDisog() {
    const result = {};
    const data = await this.playDataService.getStats();

    // Group songs by album
    for (const item of data) {
      if (!result[item.album]) {
        result[item.album] = {
          tracks: {},
          totalPlays: 0 // Initialize totalPlays for the album
        };
      }
      if (!result[item.album].tracks[item.track]) {
        result[item.album].tracks[item.track] = {
          playCount: 0,
          uniqueListeners: new Set(),
          plays: []
        };
      }
      result[item.album].tracks[item.track].playCount++;
      result[item.album].totalPlays++; // Increment total plays for the album
      result[item.album].tracks[item.track].uniqueListeners.add(item.user);
      result[item.album].tracks[item.track].plays.push({
        city: item.city,
        country: item.country,
        state: item.state,
        date: item.date,
        user: item.user
      });
    }

    // Convert Sets to counts for unique listeners
    for (const album in result) {
      for (const track in result[album].tracks) {
        const trackData = result[album].tracks[track];
        trackData.uniqueListenerCount = trackData.uniqueListeners.size; // Convert Set size to uniqueListenerCount
      }
    }

    // Fetch and assign artwork for each album
    for (const album of Object.keys(result)) {
      const artUrl = await this.playDataService.getArt(this.toCamelCase(album));
      result[album].art = artUrl;
    }

    this.result = result; // Ensure this.result is declared in your component
    return result;
  }

  openSong(artwork, trackName, albumName) {
    // Access detailed play data for the song
    const plays = this.result[albumName].tracks[trackName].plays;
    const songIdentifier = this.toCamelCase(`${trackName}`);

    // Use the service to set the play data
    const data = {
      name: trackName,
      album: albumName,
      plays: plays,
      artWork: artwork
    };
    this.playDataService.setPlayData(data);

    // Navigate to the songs component with the identifier
    this.router.navigate(['/songs', songIdentifier]);
    // Implement functionality to display these details as needed
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  ngOnInit(): void {}

  editPost(postUid?: string) {
    // if (postUid) {
    //   this.router.navigate([`/edit/${postUid}`]);
    // } else {
    //   this.router.navigate([`/edit/${this.database.getNewUid()}`]);
    // }
  }

  readPost(postUid: string) {
    this.router.navigate([`/post/${postUid}`]);
  }

  addContactToDatabase(email, firstName) {
    // const contact: Contact = { email, firstName };
    // // use email as the firebase document uid in case the user tries to sign up multiple times.
    // // emails are safe to use as the uid as they are guaranteed to be unique.
    // return this.database.set(contact, `contacts/${email}`);
  }

  goPortal() {
    axios
      .post('https://tt.apps4artists.uk/backend/create-customer-portal-session', {
        id: this.currentUser$.stripeId
      })
      .then(async (response: any) => {
        console.log('portal opening', response);
        if (response) {
          const sessionData = await response.data;
          // Redirect the user to the Customer Portal URL
          window.location.href = sessionData.url;
        }
      })
      .catch((error) => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });
  }

  subscribe(price: string): void {
    console.log(price);
    axios
      .post('https://tt.apps4artists.uk/backend/subscriptions', {
        stripeId: this.user.stripeId,
        //  email: this.currentUser$?.email, // Replace with the user's email
        priceId: price // Replace with the selected price ID
      })
      .then((response) => {
        console.log('Subscription being created:', response);
        this.redirectToCheckout(response.data.stripeSessionId);
        // if (this.user !== null) {
        //   console.log("paid")
        //   const userId = this.user.id; // Replace with the actual user ID
        //   this.updateUserTier(userId, 'Paid');

        // }
        // Handle successful subscription creation
      })
      .catch((error) => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });
  }

  async redirectToCheckout(sessionId: string) {
    const stripe = await loadStripe(environment.stripe);

    if (stripe !== null) {
      stripe.redirectToCheckout({ sessionId }).then((result: { error: any }) => {
        if (result.error) {
          console.error('Failed to redirect to checkout:', result.error);
          // Handle error
        }
      });
    }
  }

  async sendToCustomerPortal() {
    axios.post('https://www.hq.apps4artists.uk/backend/create-customer-portal-session', this.user.stripeId).then(
      (response: any) => {
        // Redirect the user to the returned URL using Angular's Router or window.location.href
        window.location.href = response.url;
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    // had to update firebase.app().functions() to firebase.default.functions() and
    // removed the region from the functions call (from stripe firebase extension docs)
    // const functionRef = firebase.default
    //   .functions()
    //   .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    // console.log(data);
    // window.location.assign(data.url);
  }
}
