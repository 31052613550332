import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import * as d3 from 'd3';
import { HttpClient } from '@angular/common/http';
import { PlaysService } from '../shared/services/plays/plays.service';

interface CountryData {
  playCount: number;
  uniqueUserCount: number;
  uniqueUsers: Set<string>;
}

@Component({
  selector: 'app-plays-map',
  templateUrl: './plays-map.component.html',
  styleUrls: ['./plays-map.component.scss']
})
export class PlaysMapComponent implements OnInit, AfterViewInit {
  private map: L.Map;
  private geoJsonLayer: L.GeoJSON;
  private geoJsonUrl = 'http://geojson.xyz/naturalearth-3/ne_50m_admin_0_countries.geojson'; // GeoJSON URL

  countryData: any = {};

  constructor(private http: HttpClient, private play: PlaysService) {}

  async getDisog() {
    const result = {};
    const data = await this.play.returnStreams();
    this.groupDataByCountry(data);
  }

  groupDataByCountry(data: any[]): void {
    data.forEach((item) => {
      const country = item.country;
      if (!this.countryData[country]) {
        this.countryData[country] = { playCount: 0, uniqueUsers: new Set() };
      }
      this.countryData[country].playCount++;
      this.countryData[country].uniqueUsers.add(item.user);
    });

    // Convert Sets to counts for unique users
    Object.keys(this.countryData).forEach((country) => {
      this.countryData[country].uniqueUserCount = this.countryData[country].uniqueUsers.size;
    });
    console.log(this.countryData);
  }

  ngOnInit() {
    this.initMap();
    this.loadGeoJson();
  }

  ngAfterViewInit() {
    this.getDisog();
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.countryData && !changes.countryData.firstChange) {
  //     this.updateMapColors();
  //   }
  // }

  private initMap(): void {
    this.map = L.map('map').setView([20, 0], 2); // Centered and zoomed out to show the world

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  private loadGeoJson(): void {
    this.http.get(this.geoJsonUrl).subscribe((geoJson: any) => {
      this.geoJsonLayer = L.geoJSON(geoJson, {
        style: (feature) => this.style(feature),
        onEachFeature: (feature, layer) => this.onEachFeature(feature, layer)
      }).addTo(this.map);
    });
  }

  private style(feature: any): any {
    const country = feature.properties.name.toLowerCase();
    const plays = this.countryData[country]?.playCount || 0;
    const colorScale = d3
      .scaleSequential(d3.interpolateReds)
      .domain([0, d3.max(Object.values(this.countryData).map((d: any) => d.playCount))]);

    return {
      fillColor: colorScale(plays),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  }

  private onEachFeature(feature: any, layer: L.Layer): void {
    const country = feature.properties.name;
    const plays = this.countryData[country.toLowerCase()]?.playCount || 0;

    layer.bindPopup(`<b>${country}</b><br>Plays: ${plays}`);
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight
    });
  }

  private highlightFeature(e: L.LeafletMouseEvent): void {
    const layer = e.target;

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });

    layer.bringToFront();
  }

  private resetHighlight(e: L.LeafletMouseEvent): void {
    this.geoJsonLayer.resetStyle(e.target);
  }

  private updateMapColors(): void {
    this.geoJsonLayer.eachLayer((layer) => {
      const feature = (layer as L.GeoJSON).feature;
      const newStyle = this.style(feature);
      (layer as L.Path).setStyle(newStyle);
    });
  }
}
